'use client'

// @TODO: use client wirklich notwendig? app router invariant

import { type PaginationProps } from '.';

import Link from '@components/atoms/link';
import classNames from 'classnames';
import useQueryParams from '@hooks/useQueryParams';

const Pagination = ({ count }: PaginationProps) => {
    const { createQueryString, pathname, queryParams } = useQueryParams();

    const currentPage = parseInt(queryParams.get('page') ?? '1')

    return (
        <ul className={'pagination'}>
            <li key={'pagination_item_first'} className={'page-item'}>
                {1 !== currentPage ? (
                    <Link href={`${pathname}`} title={'First page'} className={'page-link'} disabled={1 === currentPage}></Link>
                ) : (
                    <span className={'page-link'}></span>
                )}
            </li>
            {[...Array(count)].map((_, number: number) => (
                <li key={'pagination_item' + number} className={'page-item'}>
                    <Link
                        href={`${pathname}${number !== 0 ? '?' + createQueryString('page', number + 1) : ''}`}
                        className={'text-decoration-none'}
                    >
                        <span className={classNames('page-link', {
                            'bg-secondary  text-white': (number + 1) === currentPage,
                            'bg-white': (number + 1) !== currentPage
                        })}
                        >{number + 1}</span>
                    </Link>
                </li>
            ))}
            <li key={'pagination_item_last'} className={'page-item'}>
                {count > currentPage ? (
                    <Link href={`${pathname}?${createQueryString('page', count)}`} title={'Last page'} className={'page-link'} disabled={count === currentPage}></Link>
                ) : (
                    <span className={'page-link'}></span>
                )}
            </li>
        </ul>
    )
}

export default Pagination