'use client'

import { type ButtonProps as ButtonComponentProps, default as ButtonComponent } from "@components/atoms/button"
import useQueryParams from "@hooks/useQueryParams"

const Button = ({ children, ...props }: ButtonComponentProps) => {
    const { setQueryParam, queryParams } = useQueryParams();

    const handleClick = () => setQueryParam('filter', !queryParams.get('filter') ? '1' : null);

    return (
        <ButtonComponent className={'me-0  me-xl-auto  flex-fill  flex-xl-grow-0  rounded-0  icon--transition'} onClick={handleClick} variant={'outline-dark'} icon={'filter'} iconWidth={'sm'} {...props}>
            {children}
        </ButtonComponent>
    )
}

export default Button

